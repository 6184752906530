import React from 'react';
import logo from '../images/notbad.png';
import bnlogo from '../images/bn.png';
import '../App.css';

export const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>

      <div className="center-content">
        <div className="white-shadow" style={{ borderRadius: 20 }}>
          <img src={bnlogo} style={{ width: 100, height: 100, borderRadius: 50 }} alt="bnlogo" />
        </div>
        <p>Buenas Noticias</p>
        <button onClick={() => window.location.href = "esteban@notdbad.cl"}>
          Contáctanos por email
        </button>
      </div>
    </div>
  );
}
