import React, { ReactNode } from 'react';
import { Box } from '../Box';
import { Space } from '~/design-system/designTokens';


interface RowsProps {
    space?: Space;
    alignHorizontal?: 'center' | 'left' | 'right';
    alignVertical?: 'bottom' | 'center' | 'top' | 'justify';
    children: ReactNode;
}

export function Rows({
    space = '8px',
    alignHorizontal,
    alignVertical,
    children,
}: RowsProps) {
    const horizontalClasses = {
        center: 'items-center',
        left: 'items-start',
        right: 'items-end',
    };

    const verticalClasses = {
        bottom: 'justify-end',
        center: 'justify-center',
        top: 'justify-start',
        justify: 'justify-between',
    };

    return (
        <Box
            className={`flex flex-col flex-grow h-full w-full gap-y-${space.replace(
                'px',
                '',
            )} 
        ${alignHorizontal ? horizontalClasses[alignHorizontal] : ''} 
        ${alignVertical ? verticalClasses[alignVertical] : ''}`}
        >
            {React.Children.map(children, (child) => {
                if (isValidRow(child)) {
                    return React.cloneElement(child, {});
                }
                return <Row>{child}</Row>;
            })}
        </Box>
    );
}

interface RowProps {
    height?: 'content' | 'full' | '1/2' | '1/3' | '1/4' | '1/5';
    children: ReactNode;
}

export const Row: React.FC<RowProps> = ({ children, height = 'content' }) => {
    const heightClasses = {
        content: 'flex-shrink-0',
        full: 'flex-grow',
        '1/2': 'h-1/2',
        '1/3': 'h-1/3',
        '1/4': 'h-1/4',
        '1/5': 'h-1/5',
    };

    return <Box className={`${heightClasses[height]}`}>{children}</Box>;
};

function isValidRow(child: ReactNode): child is React.ReactElement<RowProps> {
    return React.isValidElement(child) && child.type === Row;
}
