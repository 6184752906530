import React, { useMemo } from 'react';
import { useFetchNewsForToday } from '../hooks/useFetchNewsForToday';
import { Box, Inline, Stack, Text } from '../design-system';
import { Row, Rows } from '../design-system/components/Rows';
import appstore from '../images/appstore.svg';
import playstore from '../images/playstore.svg';
import { getPlatform } from '../utils/platform';
import { useParams } from 'react-router-dom';
import { useFetchNewById } from '../hooks/useFetchNewById';

const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=cl.notbad.bn'
const IOS_LINK = 'https://apps.apple.com/us/app/bn/id6470849051'

export const TodayGoodNew = () => {
  const params = useParams();
  const newId = params.newId;

  const { data: newsForToday, isLoading: newsForTodayIsLoading, error: newsForTodayIsError } = useFetchNewsForToday({ enabled: !newId });
  const { data: newById, isLoading: newByIdIsLoading, error: newByIdIsError } = useFetchNewById({ id: !!newId ? newId : '', enabled: !!newId });

  const { news, isLoading, error } = useMemo(() => {
    return {
      news: !!newId ? [newById] : newsForToday,
      isLoading: !!newId ? newByIdIsLoading : newsForTodayIsLoading,
      error: !!newId ? newByIdIsError : newsForTodayIsError
    }
  }, [newById, newByIdIsError, newByIdIsLoading, newId, newsForToday, newsForTodayIsError, newsForTodayIsLoading])

  const platform = getPlatform()
  return (
    <Box className="h-full bg-dark min-h-screen flex justify-center items-center" padding='20px'>
      <Box className='w-full lg:w-1/2'>
        <Stack space={20}>
          <Box borderRadius='20px'>
            <Box className='justify-center' background='dark'>
              <Stack space={10}>
                <Text textAlign='center' size="14px" weight='bold' color="white">{'Descarga la app en tu teléfono'}</Text>
                <Inline alignHorizontal='center' space='20px'>
                  {platform !== 'android' ? <a href={IOS_LINK} target="_blank" rel="noopener noreferrer">
                    <Box
                      style={{ width: 90, height: 90, borderRadius: 20 }}
                      className='flex justify-center items-center bg-dark-secondary'
                      padding='10px' paddingTop='14px'>
                      <Stack align='between'>
                        <Inline alignHorizontal='center'>
                          <img src={appstore} style={{ width: 40, height: 40 }} alt="playstore" />
                        </Inline>
                        <Text size="14px" textAlign='center' weight='medium' color='white'>{'iOS'}</Text>
                      </Stack>
                    </Box>
                  </a> : null}
                  {platform !== 'ios' ? <a href={ANDROID_LINK} target="_blank" rel="noopener noreferrer">
                    <Box
                      style={{ width: 90, height: 90, borderRadius: 20 }}
                      className='flex justify-center items-center bg-dark-secondary'
                      background='default'
                      padding='10px' paddingTop='14px'>
                      <Stack align='between'>
                        <Inline alignHorizontal='center'>
                          <img src={playstore} style={{ width: 40, height: 40 }} alt="playstore" />
                        </Inline>
                        <Text size="14px" textAlign='center' weight='medium' color='white'>{'Android'}</Text>
                      </Stack>
                    </Box>
                  </a> : null}
                </Inline>
              </Stack>
            </Box>
          </Box>
          <Box className='bg-dark-secondary' borderRadius='20px'>
            <Box padding='20px'>
              <Rows alignHorizontal="center" space='12px'>
                {isLoading && <Row><Text size="12px" weight='bold' color="white">{'Cargando...'}</Text></Row>}
                {error && <Row><p>Error cargando noticias: {error.message}</p></Row>}

                {news && news.length > 0 ? (
                  news.map((newsItem, index) => (
                    newsItem && <Row key={index}>
                      <Stack space={12}>
                        <Box borderRadius='12px'>
                          <img
                            src={newsItem.image}
                            alt={newsItem.title}
                            className="object-cover h-48 w-full rounded"
                            style={{ overflow: 'clip', borderRadius: 20 }}
                          />
                        </Box>
                        <Text size="14px" weight='bold' color="white">{newsItem.title}</Text>
                        {newsItem.description.map((desc, descIndex) =>
                          <Text key={descIndex} color="white">{desc}</Text>
                        )}
                        <a href={newsItem.sourceUrl} style={{ color: 'white' }} target="_blank" rel="noopener noreferrer">Leer más</a>
                      </Stack>
                    </Row>
                  ))
                ) : (
                  !isLoading && <Row><p>No hay noticias disponibles para hoy.</p></Row>
                )}
              </Rows>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
