import React, { ReactNode } from "react";
import { Space } from "~/design-system/designTokens";
import { Box } from "../Box";

interface InlineProps {
    children: ReactNode;
    className?: string;
    space?: Space;
    alignVertical?: 'start' | 'center' | 'end';
    alignHorizontal?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
}

export const Inline: React.FC<InlineProps> = ({ children, className, space, alignVertical, alignHorizontal }) => {
    const childrenArray = React.Children.toArray(children);

    const renderedChildren = childrenArray.map((child, index) => (
        <div
            key={index}
            style={{ marginRight: index !== childrenArray.length - 1 ? space : undefined }}
        >
            {child}
        </div>
    ));

    const getVerticalAlignmentClass = (alignment: InlineProps['alignVertical']) => {
        switch (alignment) {
            case 'start':
                return 'items-start';
            case 'center':
                return 'items-center';
            case 'end':
                return 'items-end';
            default:
                return 'items-center'; // default or fallback alignment
        }
    };

    const getHorizontalAlignmentClass = (alignment: InlineProps['alignHorizontal']) => {
        switch (alignment) {
            case 'start':
                return 'justify-start';
            case 'center':
                return 'justify-center';
            case 'end':
                return 'justify-end';
            case 'between':
                return 'justify-between';
            case 'around':
                return 'justify-around';
            case 'evenly':
                return 'justify-evenly';
            default:
                return ''; // default or fallback alignment
        }
    };

    const verticalAlignmentClass = getVerticalAlignmentClass(alignVertical);
    const horizontalAlignmentClass = getHorizontalAlignmentClass(alignHorizontal);

    return (
        <Box height="full" className={`flex ${verticalAlignmentClass} ${horizontalAlignmentClass}${className ? ` ${className}` : ''}`}>
            {renderedChildren}
        </Box>
    );
}
