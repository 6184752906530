import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Política de Privacidad</h1>
        <p>Última actualización: 15 de Noviembre de 2023</p>

        <p>Not Bad SpA ("nosotros", "nos", o "nuestro") opera la aplicación móvil "Buenas Noticias" (en adelante, el "Servicio").</p>

        <p>Esta página le informa de nuestras políticas con respecto a la recopilación, uso y divulgación de datos cuando utiliza nuestro Servicio.</p>

        <p>Utilizamos sus datos para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta política.</p>

        <h2>Recopilación y Uso de la Información</h2>
        <p>Recopilamos información necesaria para el funcionamiento efectivo del Servicio. Esta recopilación es limitada y se enfoca en datos técnicos y de uso.</p>

        <h3>Tipos de Datos Recopilados</h3>
        <h4>Datos de Uso y Técnicos</h4>
        <p>Podemos recopilar información técnica sobre su dispositivo y cómo interactúa con nuestro Servicio. Esto puede incluir:</p>
        <ul>
            <li>Información técnica sobre su dispositivo móvil (modelo, sistema operativo).</li>
            <li>Datos de uso del Servicio como errores técnicos, interacciones con funciones específicas y patrones de uso general.</li>
        </ul>
        <p><strong>Nota:</strong> No recopilamos datos personales identificables como direcciones de correo electrónico, ya que utilizamos inicios de sesión anónimos.</p>

        <h3>Uso de Datos</h3>
        <p>Not Bad SpA utiliza los datos recopilados para varios fines:</p>
        <ul>
            <li>Para proporcionar y mantener nuestro Servicio.</li>
            <li>Para notificarle sobre cambios en nuestro Servicio.</li>
            <li>Para permitirle participar en funciones interactivas de nuestro Servicio.</li>
            <li>Para proporcionar análisis o información valiosa para que podamos mejorar el Servicio.</li>
            <li>Para monitorear el uso del Servicio.</li>
            <li>Para detectar, prevenir y abordar problemas técnicos.</li>
        </ul>

        <h3>Transferencia de Datos</h3>
        <p>Sus datos pueden ser transferidos y mantenidos en computadoras ubicadas fuera de su jurisdicción donde las leyes de protección de datos pueden diferir.</p>

        <h3>Divulgación de Datos</h3>
        <p><strong>Not Bad SpA</strong> puede divulgar datos de uso para cumplir con la ley, proteger nuestros derechos o la seguridad de nuestros usuarios y del público.</p>

        <h3>Seguridad de los Datos</h3>
        <p>La seguridad de sus datos es importante para nosotros. Implementamos medidas para proteger sus datos, pero recuerde que ningún método de seguridad es infalible.</p>
    
    </div>
  );
};
