import React, { ReactNode } from 'react';
import { Align } from '~/design-system/designTokens';

type StackProps = {
    children: ReactNode | ReactNode[];
    space?: number;
    align?: Align
};

export const Stack: React.FC<StackProps> = ({ children, space, align }) => {

    const getAlignmentClass = (alignment: StackProps['align'] | undefined) => {
        switch (alignment) {
            case 'start':
                return 'justify-start';  // Aligns children to the top of the container
            case 'center':
                return 'justify-center'; // Centers children vertically
            case 'end':
                return 'justify-end';    // Aligns children to the bottom
            case 'between':
                return 'justify-between';// Even spacing between children, but not before the first child or after the last child
            case 'around':
                return 'justify-around'; // Even spacing between, before, and after children
            case 'evenly':
                return 'justify-evenly'; // Even spacing between every child
            default:
                return '';
        }
    };


    const alignmentClass = getAlignmentClass(align);

    return (
        <div className={`h-full flex flex-col ${alignmentClass}`}>
            {(React.Children.toArray(children)[0])}
            {React.Children.toArray(children).slice(1).map((ch, i) => (
                <div key={i} style={{ marginTop: space ? `${space}px` : undefined }}>
                    {ch}
                </div>
            ))}
        </div>
    );
};
