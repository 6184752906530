import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config';
import { NewsItem } from '../types';

export async function fetchNewById(id: string): Promise<NewsItem | null> {
  const newsDocRef = doc(db, 'news', id);
  const newsDocSnap = await getDoc(newsDocRef);

  if (newsDocSnap.exists()) {
    // Assuming 'NewsItem' is a type that matches the document data structure
    return { id, ...newsDocSnap.data() } as NewsItem;
  } else {
    // Return null or throw an error if the news item doesn't exist
    return null;
  }
}

export async function fetchNewsForDate(date: string): Promise<NewsItem[]> {
  const dayDocRef = doc(db, 'days', date);
  const dayDocSnap = await getDoc(dayDocRef);

  if (!dayDocSnap.exists()) {
    throw new Error(`No news found for the date: ${date}`);
  }

  const newsItemIds: string[] = dayDocSnap.data()?.newsItems || [];
  const newsItems: NewsItem[] = [];

  for (const id of newsItemIds) {
    const newsDocRef = doc(db, 'news', id);
    const newsDocSnap = await getDoc(newsDocRef);

    if (newsDocSnap.exists()) {
      // Assuming 'NewsItem' is a type that matches the document data structure
      newsItems.push({ id, ...newsDocSnap.data() } as NewsItem);
    }
  }

  return newsItems;
}

export async function fetchNewsForDates(dates: string[]): Promise<NewsItem[]> {
  const allNewsItems: NewsItem[] = [];

  for (const date of dates) {
    const dayDocRef = doc(db, 'days', date);
    const dayDocSnap = await getDoc(dayDocRef);

    if (dayDocSnap.exists()) {
      const newsItemIds: string[] = dayDocSnap.data()?.newsItems || [];
      const newsItemPromises = newsItemIds.map(async id => {
        const newsDocRef = doc(db, 'news', id);
        const newsDocSnap = await getDoc(newsDocRef);

        if (newsDocSnap.exists()) {
          return { id, ...newsDocSnap.data(), date } as NewsItem;
        }
        return null;
      });

      const newsItems = (await Promise.all(newsItemPromises)).filter(item => item !== null) as NewsItem[];
      allNewsItems.push(...newsItems);
    }
  }

  return allNewsItems.sort((a, b) => ((a.date || 0) < (b.date || 0) ? 1 : -1));
}
