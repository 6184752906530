import { ReactNode } from "react";
import { BackgroundColor, BorderRadius, Height, Overflow, Space, Width } from "~/design-system/designTokens";

type BoxProps = {
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties;
    height?: Height;
    width?: Width;
    padding?: Space;
    paddingTop?: Space;
    paddingBottom?: Space;
    paddingLeft?: Space;
    paddingRight?: Space;
    overflow?: Overflow;
    background?: BackgroundColor;
    borderRadius?: BorderRadius;
};

export const Box: React.FC<BoxProps> = ({
    children,
    className,
    style,
    height,
    width,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    overflow,
    background,
    borderRadius
}) => {

    const getSizeClassOrStyle = (size: Height | Width | undefined, dimension: 'height' | 'width') => {
        if (typeof size === 'number') {
            return { className: '', style: { [dimension]: `${size}px` } };
        } else if (typeof size === 'string') {
            const prefix = dimension.charAt(0);
            switch (size) {
                case 'full': return { className: '', style: { [dimension]: '100%' } };
                case '1/2': return { className: `${prefix}-1/2`, style: {} };
                case '1/3': return { className: `${prefix}-1/3`, style: {} };
                case '1/4': return { className: `${prefix}-1/4`, style: {} };
                case '1/5': return { className: `${prefix}-1/5`, style: {} };
                default: return { className: '', style: {} };
            }
        }
        return { className: '', style: {} };
    };

    const paddingStyle = {
        paddingTop: paddingTop || padding,
        paddingBottom: paddingBottom || padding,
        paddingLeft: paddingLeft || padding,
        paddingRight: paddingRight || padding,
    };

    const heightClassOrStyle = getSizeClassOrStyle(height, 'height');
    const widthClassOrStyle = getSizeClassOrStyle(width, 'width');
    const backgroundClass = background ? `bg-${background}` : '';

    const borderRadiusStyle: React.CSSProperties = {};
    if (borderRadius) {
        if (borderRadius === 'round') {
            borderRadiusStyle.borderRadius = '50%';
        } else {
            borderRadiusStyle.borderRadius = borderRadius;
        }
    }

    return (
        <div
            className={`${className || ''} ${heightClassOrStyle.className} ${widthClassOrStyle.className} ${backgroundClass}`}
            style={{
                ...style,
                ...heightClassOrStyle.style,
                ...widthClassOrStyle.style,
                ...paddingStyle,
                overflow,
                ...borderRadiusStyle
            }}
        >
            {children}
        </div>
    );
};
