import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TodayGoodNew } from './pages/TodayGoodNew';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Analytics } from '@vercel/analytics/react';

const queryClient = new QueryClient();

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <Analytics />
      <Router>
        <Routes>
          <Route path="/bn" element={<TodayGoodNew />} />
          <Route path="/bn/:newId" element={<TodayGoodNew />} />
          <Route path="/bn-privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
