export const getPlatform = () => {
    const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'ios';
    }
    return 'other'; // Default to 'other' for non-iOS and non-Android devices
}
  