import {useQuery} from 'react-query';
import {fetchNewById} from '../firebase/services/newsService';
import { NewsItem } from '~/firebase/types';

export const useFetchNewById = ({id, enabled}: {id: string,enabled?: boolean}) => {
  return useQuery<NewsItem | null, Error>(
    ['newById', id],
    () => fetchNewById(id),
    {staleTime: 60 * 60 * 1000, enabled},
  );
};
