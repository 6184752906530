import {useQuery} from 'react-query';
import {fetchNewsForDate} from '../firebase/services/newsService';
import {NewsItem} from '../firebase/types';

export const useFetchNewsForToday = ({enabled}: {enabled?: boolean}) => {
  const today = new Date().toISOString().split('T')[0];
  return useQuery<NewsItem[], Error>(
    ['newsForToday', today],
    () => fetchNewsForDate(today),
    {staleTime: 60 * 60 * 1000, enabled},
  );
};
