import React, { ReactNode, CSSProperties } from 'react';
import { FontSize, FontWeight, TextColor } from '~/design-system/designTokens';

interface TextProps {
  children: ReactNode;
  style?: CSSProperties;
  weight?: FontWeight;
  size?: FontSize;
  color?: TextColor;
  textAlign?: 'left' | 'center' | 'right' | 'justify'; // New prop
}

export const Text: React.FC<TextProps> = ({ children, style, weight, size, color, textAlign }) => {

  const getTextAlignClass = (textAlign: 'left' | 'center' | 'right' | 'justify' | undefined) => {
    switch (textAlign) {
      case 'left':
        return 'text-left';
      case 'center':
        return 'text-center';
      case 'right':
        return 'text-right';
      case 'justify':
        return 'text-justify';
      default:
        return '';
    }
  };

  const getFontWeightClass = (weight: FontWeight | undefined) => {
    switch (weight) {
      case 'normal':
        return 'font-normal';
      case 'medium':
        return 'font-medium';
      case 'bold':
        return 'font-bold';
      default:
        return 'font-normal';
    }
  };

  const getFontSizeClass = (size: FontSize | undefined) => {
    switch (size) {
      case '8px':
        return 'text-xs';
      case '10px':
        return 'text-sm';
      case '12px':
        return 'text-base';
      case '14px':
        return 'text-lg';
      default:
        return 'text-base';
    }
  };

  const getTextColorClass = (color: TextColor | undefined) => {
    switch (color) {
      case 'default':
        return 'text-default';
      case 'black':
        return 'text-black';
      case 'white':
        return 'text-white';
      case 'light-gray':
        return 'text-gray-400'; // Assuming you have the default tailwind grays configured
      case 'teal':
        return 'text-teal-500'; // A mid-shade of teal, assuming you have the default tailwind colors configured
      default:
        return 'text-black';
    }
  };

  const fontWeightClass = getFontWeightClass(weight);
  const fontSizeClass = getFontSizeClass(size);
  const textColorClass = getTextColorClass(color);
  const textAlignClass = getTextAlignClass(textAlign);

  return (
    <p
      className={`${fontWeightClass} ${fontSizeClass} ${textColorClass} ${textAlignClass}`}
      style={style}
    >
      {children}
    </p>
  );
};
